<template>
  <div>
    <ek-input-select
      :options="activitiesList"
      :rules="[{ type: 'required', message: 'اسم الفعالية' }]"
      name="اسم الفعالية"
      placeholder="اختر اسم الفعالية "
      label="اسم الفعالية"
      v-model="notificationDto.filter.senderId"
    ></ek-input-select>

    <ek-input-text
      name="نوع الحساب"
      readonly
      placeholder="أدخل نوع الحساب المرسل إليه "
      label=" نوع الحساب المرسل إليه"
      :options="accountTypes"
      value="المستخدمون"
    ></ek-input-text>

    <ek-input-select
      :options="[{ id: null, langName: 'الكل' }, ...cities]"
      name="اسم المدينة"
      placeholder="اختر اسم المدينة"
      label="المدينة"
      textLabel="langName"
      v-model="notificationDto.filter.cityId"
    ></ek-input-select>
    <ek-input-text
      type="number"
      v-if="notificationDto.filter.isForVisitors"
      :rules="[
        { type: 'min_value:1', message: 'الحد الادنى زيارة واحدة' },
        { type: 'required', message: 'عدد الزيارات مطلوب' },
      ]"
      name="عدد الزيارات"
      placeholder="أدخل عدد الزيارات "
      label="عدد الزيارات"
      v-model.lazy="notificationDto.filter.visitorTimes"
    ></ek-input-text>

    <b-row class="no-gutters g-0">
      <b-col>
        <div class="flex-center">
          <label class="m-0 mx-1">الزوار فقط</label>
          <b-form-checkbox
            name="الزوار فقط"
            v-model="notificationDto.filter.isForVisitors"
          ></b-form-checkbox>
        </div>
      </b-col>

      <b-col>
        <div class="flex-center">
          <label class="m-0 mx-1">المتابعين فقط</label>
          <b-form-checkbox
            name="المتابعين فقط"
            v-model="notificationDto.filter.isForFollowers"
          ></b-form-checkbox>
        </div>
      </b-col>
    </b-row>

    <ek-input-select
      :options="filterdActivities"
      name="ارسال الى"
      placeholder="الكل"
      label="إرسال الى "
      multiple
      v-model="notificationDto.receiversIds"
    ></ek-input-select>

    <ek-input-text
      :rules="[{ type: 'required', message: 'عنوان الإشعار مطلوب' }]"
      placeholder="أدخل عنوان الإشعار "
      label="عنوان الإشعار"
      v-model="notificationDto.title"
      name="عنوان الاشعار"
    ></ek-input-text>

    <ek-input-textarea
      :rules="[{ type: 'required', message: 'نص الإشعار مطلوب' }]"
      placeholder="أدخل نص الإشعار"
      label="نص الإشعار"
      name="نص الإشعار"
      v-model="notificationDto.body"
    ></ek-input-textarea>

    <label style="font-size: 14px"> صورة الإشعار</label>
    <ek-input-image
        label="صورة الاشعار"
        name="صور الاشعار"
        @input="updateNotificationFile($event)"
    >
    </ek-input-image>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      timer: false,
    };
  },
  computed: {
    ...mapState({
      notificationDto: (state) => state.notifications.notificationDto,
      filterdActivities: (state) => state.notifications.filterdActivities,
      activitiesList: ({ activities }) => activities.activities,
      accountTypes: (state) => state.accounts.accountTypes,
      cities: (state) => state.locations.cities,
    }),
    senderId() {
      return this.$store.state.notifications.notificationDto.filter.senderId;
    },
    isForVisitors() {
      return this.$store.state.notifications.notificationDto.filter.isForVisitors;
    },
    isForFollowers() {
      return this.$store.state.notifications.notificationDto.filter.isForFollowers;
    },
    visitorTimes() {
      return this.$store.state.notifications.notificationDto.filter.visitorTimes;
    },
  },
  mounted() {
    this.$store.commit("Set_Notification_Filter", {
      senderId: "",
      cityId: null,
      isForVisitors: false,
      visitorTimes: 1,
      isForFollowers: false,
    });
  },

  methods: {
    ...mapActions(["getFilterByActivity"]),

    getFilterdUsersList() {
      if (this.notificationDto.filter.senderId)
        this.getFilterByActivity(this.notificationDto.filter);
    },

    // setExcluded(e) {
    //   this.notificationDto.receiversIds = this.filterdActivities
    //     .filter((el) => !e.includes(el.id))
    //     .map((el) => el.id);
    // },
    updateNotificationFile($event) {
      this.notificationDto.image = $event.file;
    },
  },
  watch: {
    senderId(nv) {
      this.$store.commit("Reset_Filter_Results");

      if (nv) this.getFilterdUsersList();
    },
    cityId(e) {
      if (!e) {
        this.notificationDto.filter.cityId = null;
      }
      this.$store.commit("Reset_Filter_Results");

      this.getFilterdUsersList();
    },
    isForVisitors() {
      this.$store.commit("Reset_Filter_Results");

      this.getFilterdUsersList();
    },
    isForFollowers() {
      this.$store.commit("Reset_Filter_Results");

      this.getFilterdUsersList();
    },
    visitorTimes() {
      this.$store.commit("Reset_Filter_Results");

      this.getFilterdUsersList();
    },
    updateNotificationFile($event) {
      this.notificationDto.image = $event.file;
    },
  },
};
</script>
