<template>
  <div>
    <ek-dialog
      ref="addForm"
      title="إضافة إشعار"
      size="md"
      placeholder="ابحث عن إشعار معين"
      btnText="إشعار جديد"
      @ok="submitForm"
      @close="resetForm"
      @search="setSearch"
    >
      <template #body>
        <notification-form class="w-100" ref="notificationForm" @closeModal="closeModal"> </notification-form>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import notificationForm from "./NotificationForm.vue";
export default {
  components: {
    notificationForm,
  },
  data: () => ({}),
  methods: {
    submitForm() {
      this.$refs.notificationForm.submit();
    },
    closeModal() {
        this.resetForm()
        this.$refs.addForm.close()
    },
    resetForm() {
      this.$refs.notificationForm.reset();
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["title", "body", "sendTo", "sendBy"],
        query,
      });
    },
  },
};
</script>
