<template>
  <div class="notification-form">
    <label>مرسل الاشعار</label>
    <b-form-radio-group
      v-model="selected"
      :options="options"
      value-field="item"
      text-field="name"
    ></b-form-radio-group>
    <ValidationObserver ref="form" @close="reset">
      <component :is="selectedForm"></component>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ActivityNotificationsForm from "./ActivityNotificationsForm.vue";
import AdminNotificationsForm from "./AdminNotificationsForm.vue";
import { nullGuid } from "@/EK-core/util/global";

export default {
  component: {
    ActivityNotificationsForm,
    AdminNotificationsForm,
  },

  data() {
    return {
      selected: 0,
      options: [
        { item: 0, name: "إدارة التطبيق" },
        { item: 1, name: "الفعالية" },
      ],
    };
  },
  computed: {
    ...mapState({
      notificationDto: (state) => state.notifications.notificationDto,
    }),
    selectedForm() {
      return this.selected ? ActivityNotificationsForm : AdminNotificationsForm;
    },
    nullGuid() {
      return nullGuid;
    },
  },
  methods: {
    ...mapActions(["addNotificationAdmin", "addNotification", "getFilterByAdmin"]),
    submit() {
      this.$refs["form"].validate().then((valid) => {
        console.log(valid);
        console.log(this.selected);
        if (valid) {
          if (this.selected === 0) 
          {
              this.addNotificationAdmin(this.notificationDto)
              this.$emit("closeModal")
              this.$store.commit(this.Reset_Notification_Dto)
          }
          else {
            this.addNotification(this.notificationDto);
            this.$emit("closeModal")
          }
        }
      });
    },
    filterAdmin() {
      if (this.filterNotificationDto.accountType) {
        this.getFilterByAdmin(this.notificationDto.filter);
      }
    },

    reset() {
      this.$refs.form.reset();
      this.selected = 0;
      this.$store.commit("Set_Notification_Dto");
      this.$store.commit("Reset_Filter_Results");
      this.$store.commit("Set_Notification_Filter", {
        accountType: "",
        cityId: null,
      });
    },
  },
  watch: {
    selected() {
      this.$store.commit("Reset_Filter_Results");
      this.notificationDto.receiversIds = [];
    },
  },
};
</script>
