<template>
  <div>
    <ek-input-select
      :rules="[{ type: 'required', message: 'نوع الحساب المرسل إليه مطلوب' }]"
      name="نوع الحساب"
      placeholder="أدخل نوع الحساب المرسل إليه "
      label=" نوع الحساب المرسل إليه"
      :options="accountTypes"
      v-model="notificationDto.filter.accountType"
    ></ek-input-select>

    <ek-input-select
      :options="[{ id: null, langName: 'الكل' }, ...cities]"
      name="اسم المدينة"
      placeholder="اختر اسم المدينة"
      label="المدينة"
      textLabel="langName"
      v-model="notificationDto.filter.cityId"
    ></ek-input-select>

    <ek-input-select
      :options="filterdActivities"
      name="ارسال الى"
      placeholder="الكل"
      label="إرسال الى "
      multiple
      v-model="notificationDto.receiversIds"
    ></ek-input-select>

    <ek-input-text
      :rules="[{ type: 'required', message: 'عنوان الإشعار مطلوب' }]"
      placeholder="أدخل عنوان الإشعار "
      label="عنوان الإشعار"
      v-model="notificationDto.title"
      name="عنوان الاشعار"
    ></ek-input-text>

    
    <ek-input-textarea
      :rules="[{ type: 'required', message: 'نص الإشعار مطلوب' }]"
      placeholder="أدخل نص الإشعار"
      label="نص الإشعار"
      name="نص الإشعار"
      v-model="notificationDto.body"
    ></ek-input-textarea>
    
    <label style="font-size: 14px"> صورة الإشعار</label>
    <ek-input-image
        label="صورة الاشعار"
        name="صور الاشعار"
        @input="updateNotificationFile($event)"
    >
    </ek-input-image>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
mapActions;

export default {
  methods: {
    ...mapActions(["addNotificationAdmin", "getFilterByAdmin"]),
    // setExcluded(e) {
    //   this.notificationDto.excludedIds = this.filterdActivities
    //     .filter((el) => !e.includes(el.id))
    //     .map((el) => el.id);
    // },
    filterActivity() {
      if (this.notificationDto.filter.accountType) {
        this.getFilterByAdmin(this.notificationDto.filter);
      }
    },
    updateNotificationFile($event) {
      this.notificationDto.image = $event.file;
    },
  },
  computed: {
    ...mapState({
      notificationDto: (state) => state.notifications.notificationDto,
      filterdActivities: (state) => state.notifications.filterdActivities,
      activitiesList: ({ activities }) => activities.activities,
      accountTypes: (state) => state.accounts.accountTypes,
      cities: (state) => state.locations.cities,
    }),
    cityId() {
      return this.$store.state.notifications.notificationDto.filter.cityId;
    },
    accountType() {
      return this.$store.state.notifications.notificationDto.filter.accountType;
    },
  },
  mounted() {
    this.$store.commit("Set_Notification_Filter", {
      accountType: "",
      cityId: null,
    });
  },
  watch: {
    cityId() {
      this.$store.commit("Reset_Filter_Results");

      this.filterActivity();
    },
    accountType() {
      this.$store.commit("Reset_Filter_Results");
      this.filterActivity();
    },
  },
};
</script>
